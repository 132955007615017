<template>
  <div class="uncertan-page">
    <div class="title d-flex">
      <p>{{ title }}</p>
      <p>
        比較[{{ $moment(val).format() }}]之23:00(Hourly)數 與 Day_Final數之分別
        <span v-if="title_num === '16.9'">(包括午夜場票房)</span>
        <span v-if="title_num === '16.9.23.N'">(不包括午夜場票房)</span>
      </p>
    </div>
    <b-button
      size="sm"
      @click="$router.push({ name: 'check-upload-record' })"
      class="mb-1"
      style="margin-left: 8px"
      variant="outline-dark"
      >回目錄</b-button
    >

    <div style="display: flex; align-items: center">
      <!-- <b-form-select
        style="width: 200px"
        class="mr-2"
        v-model="select"
        :options="selectOptions"
      ></b-form-select> -->
      <b-form-checkbox
        id="checkbox-showHiddenMovie"
        v-model="showHiddenMovie"
        @change="checkShow()"
      >
        不顯示隱藏戲院記錄
      </b-form-checkbox>
    </div>
    <div class="mt-1 mb-1" v-loading="loading">
      <b style="color: #0e72bc" class="mt-1">
        List Theatre B.O. Records in
        <span style="color: red">{{ $moment(val).format() }}</span>
        <span style="color: red"> 23:00</span>
        <span
          >(UnOfficial Total on all records received
          <span
            style="color: red"
            v-if="title_num === '16.9.23' || title_num === '16.9.23.N'"
            >Without Gala B.O.</span
          >
          within in this period.)</span
        >
      </b>
      <b-table
        bordered
        striped
        hover
        responsive
        :items="tableData"
        :fields="
          $store.state.highlightCol.firTable
            ? $store.state.newTableFields
            : tableFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            tableFields,
            $store.state.newTableFields,
            'firTable'
          )
        "
      >
        <template #cell(theatre_name)="data">
          <div class="text-nowrap">
            "
            {{ data.item.theatre_name }}
          </div>
        </template>
        <template #cell(pre_adm_day_final)="data">
          <div>
            {{ parseFloat(data.item.pre_adm_day_final).toLocaleString() }}
          </div>
        </template>
        <template #cell(pre_bo_day_final)="data">
          <div>
            {{ parseFloat(data.item.pre_bo_day_final).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_adm_hourly_xml)="data">
          <div
            :style="`color:${
              data.item.h23_adm_hourly_xml === data.item.adm_final_xml
                ? ''
                : 'red'
            }`"
          >
            {{ parseFloat(data.item.h23_adm_hourly_xml).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_bo_hourly_xml)="data">
          <b
            :style="`color:${
              data.item.h23_bo_hourly_xml === data.item.bo_final_xml
                ? ''
                : 'red'
            }`"
          >
            {{ parseFloat(data.item.h23_bo_hourly_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(adm_final_xml)="data">
          <b>
            {{ parseFloat(data.item.adm_final_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(bo_final_xml)="data">
          <b>
            {{ parseFloat(data.item.bo_final_xml).toLocaleString() }}
          </b>
        </template>
      </b-table>
    </div>
    <div class="mt-3" v-loading="loading">
      <b style="color: #0e72bc" class="mt-1">
        List Movie B.O. Records in
        <span style="color: red">{{ $moment(val).format() }}</span
        ><span style="color: red"> 23:00</span>
        <span
          >(UnOfficial Total on all records received
          <span
            style="color: red"
            v-if="title_num === '16.9.23' || title_num === '16.9.23.N'"
            >Without Gala B.O.</span
          >
          within in this period.)</span
        >
      </b>
      <b-table
        bordered
        class="mt-1"
        striped
        hover
        responsive
        :items="movieData"
        :fields="
          $store.state.highlightCol.secTable
            ? $store.state.newSecTableFields
            : movieFields
        "
        @head-clicked="
          commonHighLightCol(
            $event,
            movieFields,
            $store.state.newSecTableFields,
            'secTable'
          )
        "
      >
        <template #cell(film_title_name)="data">
          <div style="width: 250px" v-html="data.item.film_title_name"></div>
        </template>
        <template #cell(h23_adm_hourly_xml)="data">
          <div
            :style="`color:${
              data.item.h23_adm_hourly_xml === data.item.adm_final_xml
                ? ''
                : 'red'
            }`"
          >
            {{ parseFloat(data.item.h23_adm_hourly_xml).toLocaleString() }}
          </div>
        </template>
        <template #cell(h23_bo_hourly_xml)="data">
          <b
            :style="`color:${
              data.item.h23_bo_hourly_xml === data.item.bo_final_xml
                ? ''
                : 'red'
            }`"
          >
            {{ parseFloat(data.item.h23_bo_hourly_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(pre_adm_day_final)="data">
          <div>
            {{ parseFloat(data.item.pre_adm_day_final).toLocaleString() }}
          </div>
        </template>
        <template #cell(pre_bo_day_final)="data">
          <b>
            {{ parseFloat(data.item.pre_bo_day_final).toLocaleString() }}
          </b>
        </template>
        <template #cell(adm_final_xml)="data">
          <b>
            {{ parseFloat(data.item.adm_final_xml).toLocaleString() }}
          </b>
        </template>
        <template #cell(bo_final_xml)="data">
          <b>
            {{ parseFloat(data.item.bo_final_xml).toLocaleString() }}
          </b>
        </template>
      </b-table>
      <p>紅色字是代表數據有異差</p>
    </div>
  </div>
</template>

<script>
import { getNightAndFinalCompare } from "@/api/record.js"

export default {
  props: {
    title: {
      type: String,
    },
    title_num: {
      type: String,
    },
    val: {
      type: String,
    },
    val1: {
      type: String,
    },
    titleText: {
      type: String,
    },
    uploadDate: {
      type: String,
    },
    filmIDList: {
      type: String,
    },
    searchType: {
      type: String,
    },
  },
  data() {
    return {
      movieData: [],
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" },
      ],
      showHiddenMovie: true,
    }
  },
  computed: {
    movieFields() {
      return [
        { key: "film_title_name", label: "Movie Name" },
        // { key: "film_effect", label: "" },
        {
          key: "pre_adm_day_final",
          label: "Pre-Sale Adm. In Day Final 預售",
        },
        { key: "pre_bo_day_final", label: "Pre-Sale B.O. In Day Final 預售" },
        {
          key: "h23_adm_hourly_xml",
          label: "Dayend File Adm. In hourly XML",
        },
        {
          key: "h23_bo_hourly_xml",
          label: "Dayend File B.O. In hourly XML",
        },
        { key: "adm_final_xml", label: "Adm. In Final XML" },
        { key: "bo_final_xml", label: "B.O. In Final XML" },
      ]
    },
    tableFields() {
      return [
        { key: "theatre", label: "Theatre Name", sortable: true },
        { key: "date_upload_ref", label: "Date Upload Ref.", sortable: true },
        {
          key: "pre_adm_day_final",
          label: `Pre-Sale Adm. In Day Final 
          預售`,
          sortable: true,
        },
        {
          key: "pre_bo_day_final",
          label: "Pre-Sale B.O. In Day Final 預售",
          sortable: true,
        },
        {
          key: "h23_adm_hourly_xml",
          label: "Dayend File Adm. In hourly XML",
          sortable: true,
        },
        {
          key: "h23_bo_hourly_xml",
          label: "Dayend File B.O. In hourly XML",
          sortable: true,
        },
        { key: "adm_final_xml", label: "Adm. In Final XML", sortable: true },
        { key: "bo_final_xml", label: "B.O. In Final XML", sortable: true },
      ]
    },
  },
  methods: {
    checkShow() {
      this.isShowAllFilm = !this.isShowAllFilm
      this.getNightAndFinalCompare()
    },
    //get 16.9 data
    getNightAndFinalCompare() {
      this.loading = true
      const data = {
        date: this.val,
        without_gala_bo: this.title_num === "16.9" ? 0 : 1,
        without_hidden: !this.isShowAllFilm,
      }
      getNightAndFinalCompare(data).then((res) => {
        this.tableData = res.data.theatreTable
        const list = []
        const movieList = res.data.movieTable
        console.log(movieList)
        movieList.map((el) => {
          console.log(el)
          el.film_effect = ""
          if (el.length === 1) {
            el[0]._rowVariant = "warning"
            const preshow =
              this.$moment() < this.$moment(el[0].film_date_release)
                ? "（優先場）"
                : ""
            const remark = el[0].film_remark_2
              ? `${el[0].film_remark_1} (${el[0].film_remark_2})`
              : el[0].film_remark_1
            el[0].film_title_name = `${el[0].film_title_name} <span style="color:red">${preshow}</span> <br/> <div style="font-size:10px">${remark} ${el[0].film_language} [${el[0].film_run_ver}]</div>`
            list.push(el[0])
          } else {
            const preshow =
              this.$moment() < this.$moment(el[0].film_date_release)
                ? "（優先場）"
                : ""
            const totalList = new Object()
            totalList.film_title_name = `${el[0].film_title_name} <span style="color:red">${preshow}</span>`
            totalList.film_date_release = el[0].film_date_release
            totalList._rowVariant = "warning"
            totalList.pre_adm_day_final = el.reduce(
              (sum, item) => sum + item.pre_adm_day_final,
              0
            )
            totalList.pre_bo_day_final = el.reduce(
              (sum, item) => sum + item.pre_bo_day_final,
              0
            )
            totalList.h23_adm_hourly_xml = el.reduce(
              (sum, item) => sum + item.h23_adm_hourly_xml,
              0
            )
            totalList.h23_bo_hourly_xml = el.reduce(
              (sum, item) => sum + item.h23_bo_hourly_xml,
              0
            )
            totalList.adm_final_xml = el.reduce(
              (sum, item) => sum + item.adm_final_xml,
              0
            )
            totalList.bo_final_xml = el.reduce(
              (sum, item) => sum + item.bo_final_xml,
              0
            )
            list.push(totalList)
            el.map((li) => {
              const effect = li.film_remark_2
                ? `${li.film_remark_1} (${li.film_remark_2})`
                : li.film_remark_1
              li.film_title_name = `${effect} ${li.film_language} [${li.film_run_ver}]`

              list.push(li)
            })
            // console.log(totalList)
          }
          // el.map((li) => {
          //   list.push(li)
          // })
        })
        console.log(list)
        this.movieData = list
        this.loading = false
      })
    },
  },
  mounted() {
    this.getNightAndFinalCompare()
  },
}
</script>
<style lang="scss">
.uncertan-page {
  .b-table {
    thead {
      tr {
        th {
          div {
            //  white-space: normal;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
